import { ApprovalTagReviewDisplay } from "@components/display/approvals-display/approval-tag-review/approval-tag-review-display";
import { RequestTagReviewDisplay } from "@components/display/request-displays/request-tag-review/request-tag-review-display";
import {
    Button,
    Card,
    Modal,
    ModalContent,
    useToast
} from "@cpchem/covalence-ui";
import { faCircleCheck, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { ApprovalPreviewTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import {
    InitialFilterState,
    RequestTagAPIResponse
} from "@services/hermes/interface";
import { Log, TagRequest } from "@services/hermes/model";
import {
    FREQUENCY_MODE_FILTER,
    FilterType,
    PULL_FREQUENCY_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { capitalizeFirstLetter } from "@utilities/capitalize-first-letter";
import { buildSelectOptionsList } from "@utilities/filters/build-select-options-list";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useService } from "../../../../../service-provider";
import { RequestTitleView } from "../../request-title-view";
import "./styles.scss";

export function ApprovalPreview(): JSX.Element {
    const { requestId } = useParams();
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { isLoading, onSetLoading } = useTagManagementContext();
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const { createToast } = useToast();
    let approveRequestPanelText = "";
    let showTagReviewTable;
    const pendingTagRequestCount = tagRequest.items?.filter(
        (tag) => tag.tagStatus?.toLowerCase() === "pending"
    ).length;

    if (pendingTagRequestCount !== 0) {
        approveRequestPanelText = ` ${pendingTagRequestCount} Tags need your attention before approving the Request`;
    } else {
        approveRequestPanelText = `All Tags have been confirmed`;
    }
    const requestCompleteStatus = "complete";

    const requestComplete =
        tagRequest.requestStatus?.toLowerCase() === requestCompleteStatus;

    async function getRequestDetails() {
        try {
            onSetLoading(true);
            const response = await hermesService.GetRequest(Number(requestId));
            if (response.data) {
                const tagActionsResponse =
                    response.data as RequestTagAPIResponse;
                const results = tagActionsResponse.data as TagRequest;
                setTagRequestDataState(results);
            }
        } catch (error) {
            console.error(error);
        } finally {
            onSetLoading(false);
        }
    }

    async function getLookupItems(filterType: FilterType) {
        try {
            const filterResponse = await hermesService.GetLookupData(
                filterType.ID
            );

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setSelectOptionsState(filterType.TITLE, data);
            }
        } catch (error) {
            setSelectOptionsState(
                filterType.TITLE,
                InitialFilterState.filterItems
            );
        }
    }

    async function setSelectOptionsState(filterName: string, data: string[]) {
        const selectOptionActions = {
            [PULL_FREQUENCY_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_PULL_FREQUENCY_SELECT_OPTIONS",
                    payload: {
                        pullFrequencySelectOptions: buildSelectOptionsList(
                            PULL_FREQUENCY_FILTER.TITLE,
                            data
                        )
                    }
                });
            },
            [FREQUENCY_MODE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_FREQUENCY_MODE_SELECT_OPTIONS",
                    payload: {
                        frequencyModeSelectOptions: buildSelectOptionsList(
                            FREQUENCY_MODE_FILTER.TITLE,
                            data
                        )
                    }
                });
            }
        };

        const action = selectOptionActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    const handleRemoveTagRow = async (
        tagName: string | undefined,
        tagStatus: string | null,
        reasonForChange: string
    ) => {
        try {
            onSetLoading(true);
            if (tagStatus) {
                const tagReviewed = tagRequest.items.find(
                    (tag) => tag.tagName === tagName
                );
                if (tagReviewed) {
                    await hermesService
                        .RemoveTag(
                            tagRequest.id,
                            tagReviewed.id,
                            tagStatus,
                            reasonForChange
                        )
                        .then(async (removeTagResponse) => {
                            if (removeTagResponse.code === 200) {
                                await hermesService
                                    .GetRequest(Number(requestId))
                                    .then((getRequestDetailsResponse) => {
                                        if (getRequestDetailsResponse.data) {
                                            const tagActionsResponse =
                                                getRequestDetailsResponse.data as RequestTagAPIResponse;
                                            const results =
                                                tagActionsResponse.data as TagRequest;
                                            setTagRequestDataState(results);

                                            createToast(
                                                `Tag ${tagName} status set to ${tagStatus}`,
                                                {
                                                    id: `tag-${tagName}-status-${tagStatus}`,
                                                    severity: "info"
                                                }
                                            );
                                        }
                                    });
                            } else {
                                const errorMessage = removeTagResponse.message
                                    ? removeTagResponse.message
                                    : "Unknown error occurred, please contact EHFIL Dev Support Group";
                                throw new Error(errorMessage);
                            }
                        });
                }
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} removal unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-not-removed`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 15000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    const handleUpdateTagRow = async (
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined,
        frequencyMode?: string | undefined
    ) => {
        try {
            onSetLoading(true);
            const tagReviewed = tagRequest.items.find(
                (tag) => tag.tagName === tagName
            );

            if (tagReviewed) {
                const updatedTag = {
                    ...tagReviewed,
                    pullFrequency: pullFrequency,
                    frequencyMode: frequencyMode,
                    comments: comments
                };

                await hermesService
                    .UpdateTag({
                        ...updatedTag,
                        ...{ requestId: tagRequest.id }
                    })
                    .then(async (updateTagResponse) => {
                        if (updateTagResponse.code === 200) {
                            await hermesService
                                .GetRequest(Number(requestId))
                                .then((getRequestDetailsResponse) => {
                                    if (getRequestDetailsResponse.data) {
                                        const tagActionsResponse =
                                            getRequestDetailsResponse.data as RequestTagAPIResponse;
                                        const results =
                                            tagActionsResponse.data as TagRequest;
                                        setTagRequestDataState(results);

                                        createToast(
                                            `Tag ${tagName} updated successfully!`,
                                            {
                                                id: `tag-${tagName}-updated`,
                                                severity: "info"
                                            }
                                        );
                                    }
                                });
                        } else {
                            const errorMessage = updateTagResponse.message
                                ? updateTagResponse.message
                                : "Unknown error occurred, please contact EHFIL Dev Support Group";
                            throw new Error(errorMessage);
                        }
                    });
            } else {
                throw new Error(
                    "Unknown error occurred, please contact EHFIL Dev Support Group"
                );
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} update unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-not-updated`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 15000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    const handleChangeTagStatus = async (
        tagName: string | undefined,
        tagStatus: string | null
    ) => {
        try {
            onSetLoading(true);
            if (tagStatus) {
                const tagReviewed = tagRequest.items.find(
                    (tag) => tag.tagName === tagName
                );
                let updatedStatus = "";
                if (tagStatus.toLowerCase() === "pending") {
                    updatedStatus = "confirmed";
                } else if (tagStatus.toLowerCase() === "confirmed") {
                    updatedStatus = "pending";
                } else if (tagStatus.toLowerCase() === "removed") {
                    updatedStatus = "pending";
                } else {
                    updatedStatus = "pending";
                }
                if (tagReviewed) {
                    await hermesService
                        .ChangeTagStatus(
                            tagRequest.id,
                            tagReviewed.id,
                            updatedStatus
                        )
                        .then(async (changeTagStatusResponse) => {
                            if (changeTagStatusResponse.code === 200) {
                                await hermesService
                                    .GetRequest(Number(requestId))
                                    .then((getRequestDetailsResponse) => {
                                        if (getRequestDetailsResponse.data) {
                                            const tagActionsResponse =
                                                getRequestDetailsResponse.data as RequestTagAPIResponse;
                                            const results =
                                                tagActionsResponse.data as TagRequest;
                                            setTagRequestDataState(results);

                                            createToast(
                                                `Tag ${tagName} status set to ${updatedStatus}`,
                                                {
                                                    id: `tag-${tagName}-status-${updatedStatus}`,
                                                    severity: "info"
                                                }
                                            );
                                        }
                                    });
                            } else {
                                const errorMessage =
                                    changeTagStatusResponse.message
                                        ? changeTagStatusResponse.message
                                        : "Unknown error occurred, please contact EHFIL Dev Support Group";
                                throw new Error(errorMessage);
                            }
                        });
                } else {
                    throw new Error(
                        "Unknown error occurred, please contact EHFIL Dev Support Group"
                    );
                }
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} status change unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-status-not-changed`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 15000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    const handleConfirmAllTagRows = async (confirmAll: string) => {
        try {
            onSetLoading(true);
            const previousStatus =
                confirmAll.toLowerCase() === "pending"
                    ? "confirmed"
                    : "pending";
            await hermesService
                .ConfirmAllTags(tagRequest.id, confirmAll)
                .then(async (confirmAllTagsResponse) => {
                    if (confirmAllTagsResponse.code === 200) {
                        await hermesService
                            .GetRequest(Number(requestId))
                            .then((getRequestDetailsResponse) => {
                                if (getRequestDetailsResponse.data) {
                                    const tagActionsResponse =
                                        getRequestDetailsResponse.data as RequestTagAPIResponse;
                                    const results =
                                        tagActionsResponse.data as TagRequest;
                                    setTagRequestDataState(results);

                                    createToast(
                                        `All ${previousStatus} Tags status set to ${confirmAll}`,
                                        {
                                            id: `all-tags-set-to-${previousStatus}`,
                                            severity: "info"
                                        }
                                    );
                                }
                            });
                    } else {
                        const errorMessage = confirmAllTagsResponse.message
                            ? confirmAllTagsResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(`Confirm All action unsuccessful. ${error.message}`, {
                id: `confirm-all-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 15000
            });
        } finally {
            onSetLoading(false);
        }
    };

    function setTagRequestDataState(updatedTagRequest: TagRequest) {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: { ...updatedTagRequest }
            }
        });
    }

    async function onApproveRequestClicked() {
        try {
            const updatedRequestStatus = "approved";
            onSetLoading(true);
            await hermesService
                .ApproveRequest(tagRequest.id, updatedRequestStatus)
                .then(async (approveRequestResponse) => {
                    if (approveRequestResponse.code === 200) {
                        await hermesService
                            .GetRequest(Number(requestId))
                            .then((getRequestDetailsResponse) => {
                                if (getRequestDetailsResponse.data) {
                                    const tagActionsResponse =
                                        getRequestDetailsResponse.data as RequestTagAPIResponse;
                                    const results =
                                        tagActionsResponse.data as TagRequest;
                                    setTagRequestDataState(results);

                                    setSuccessModalVisible(true);
                                }
                            });
                    } else {
                        const errorMessage = approveRequestResponse.message
                            ? approveRequestResponse.message
                            : "Unknown error occurred, please contact EHFIL Dev Support Group";
                        throw new Error(errorMessage);
                    }
                });
        } catch (requestProcessing) {
            const error = requestProcessing as Error;
            createToast(`Request not approved. ${error.message}`, {
                id: `request-approval-action-unsuccessful`,
                severity: "danger",
                autoDismiss: false,
                autoDismissDelay: 15000
            });
        } finally {
            onSetLoading(false);
        }
    }

    function onSuccessModalClosed() {
        setSuccessModalVisible(false);
    }

    async function getAllInitialLookupItems() {
        getLookupItems(PULL_FREQUENCY_FILTER);
        getLookupItems(FREQUENCY_MODE_FILTER);
    }

    useEffect(() => {
        getRequestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, dispatch]);

    useEffect(() => {
        getAllInitialLookupItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.confirmAll?.toLowerCase() === "confirmed")
            handleConfirmAllTagRows(state.confirmAll);
        else if (state.confirmAll?.toLowerCase() === "pending")
            handleConfirmAllTagRows(state.confirmAll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.confirmAll]);

    const disableApprovalButton =
        approveRequestPanelText !== "All Tags have been confirmed";

    if (!isLoading && !requestComplete) {
        showTagReviewTable = (
            <Card testId={ApprovalPreviewTestIds.ApprovalTagsReviewDisplayCard}>
                <ApprovalTagReviewDisplay
                    removeTagRow={handleRemoveTagRow}
                    updateTagRow={handleUpdateTagRow}
                    changeTagRowStatus={handleChangeTagStatus}
                />
            </Card>
        );
    } else if (!isLoading && requestComplete) {
        showTagReviewTable = (
            <Card testId={ApprovalPreviewTestIds.RequestTagsReviewDisplayCard}>
                <RequestTagReviewDisplay updateTagRow={handleUpdateTagRow} />
            </Card>
        );
    }
    return (
        <div className="approval-preview">
            <Card testId={ApprovalPreviewTestIds.RequestTitleViewCard}>
                <RequestTitleView
                    title={tagRequest.requestName}
                    status={capitalizeFirstLetter(tagRequest.requestStatus)}
                    lastUpdated="Last updated 20 mins ago"
                />
            </Card>
            {showTagReviewTable}
            {!requestComplete && (
                <Card testId={ApprovalPreviewTestIds.ApproveRequestPanelCard}>
                    <div className="approve-request-panel">
                        <div className="approve-request-panel-container">
                            {disableApprovalButton && (
                                <FontAwesomeIcon
                                    icon={faWarning}
                                    className="approve-request-panel-warning-icon"
                                    size="xl"
                                />
                            )}
                            {!disableApprovalButton && (
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    className="approve-request-panel-ready-icon"
                                    size="xl"
                                />
                            )}
                            <p className="approve-request-panel-text">
                                {approveRequestPanelText}
                            </p>
                        </div>
                        <Button
                            variant="solid"
                            isDisabled={disableApprovalButton}
                            size="medium"
                            text="Approve Request"
                            className="approve-request-button"
                            color="success"
                            onClick={onApproveRequestClicked}
                            testId={ApprovalPreviewTestIds.ApproveRequestButton}
                        />
                    </div>
                </Card>
            )}

            <Modal
                onRequestClose={onSuccessModalClosed}
                title="Success"
                isOpen={successModalVisible}
                className="success-modal"
                testId={ApprovalPreviewTestIds.SuccessModal}
            >
                <ModalContent
                    testId={ApprovalPreviewTestIds.SuccessModalContent}
                >
                    <div className="success-modal-question">
                        Request Approval Successful!
                    </div>
                    <div className="success-modal-text">
                        {tagRequest.requestName} request was successfully
                        Approved!
                    </div>
                </ModalContent>
            </Modal>
        </div>
    );
}
