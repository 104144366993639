import { MyRequestsDisplay } from "@components/display/request-displays/my-requests/my-requests-display";
import {
    Card,
    Container,
    DisplayAmount,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from "@cpchem/covalence-ui";
import {
    faClipboardCheck,
    faHourglass
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNextSortValues, sortValue } from "@utilities/api/helper";
import React from "react";
import { useTagManagement } from "../../../../../stores/tag-management-context";
import { SearchFilterView } from "../../search-filter-view";
import { MyRequestsSearchFilterView } from "../my-requests-search-filter-view";

type TabLabels = { [key: string]: string };

export const RequestsTableView = () => {
    const { state, dispatch } = useTagManagement();
    const {
        myRequests,
        totalPendingRequestCount,
        totalCompleteRequestCount,
        rowsToDisplay,
        currentPage,
        sortValue,
        myRequestsStatus
    } = state;

    const tabLabels: TabLabels = {
        pending: "Pending",
        complete: "Complete"
    };

    const onChange = (newStatus: string) => {
        dispatch({
            type: "SET_MY_REQUESTS_STATUS",
            payload: {
                myRequestsStatus: newStatus
            }
        });
    };

    const tabListLabel = tabLabels[myRequestsStatus] || "Complete";

    const onPageChange = (newPage: number) => {
        setCurrentPageState(newPage);
    };
    const onRowsToDisplayChange = (newRowsToDisplay: string) => {
        setRowsToDisplayState(newRowsToDisplay);
    };
    function setCurrentPageState(page: number) {
        dispatch({ type: "SET_CURRENT_PAGE", payload: page });
    }
    function setRowsToDisplayState(rowsToDisplay: string) {
        dispatch({
            type: "SET_ROWS_TO_DISPLAY",
            payload: Number(rowsToDisplay)
        });
    }

    const onSelectSortValue = (newSort: sortValue, currentSort: sortValue) => {
        const newSortValue = getNextSortValues(currentSort, newSort);
        dispatch({
            type: "SET_OrderBy_TO_DISPLAY",
            payload: newSortValue
        });
    };

    return (
        <div className="requests-content" data-testid="">
            <Tabs value={myRequestsStatus} onChange={onChange} id="Tabs">
                <Container>
                    <TabList label={tabListLabel}>
                        <Tab
                            label="Pending"
                            key="pending"
                            value="pending"
                            icon={<FontAwesomeIcon icon={faHourglass} />}
                        />
                        <Tab
                            label="Complete"
                            key="complete"
                            value="complete"
                            icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                        />
                    </TabList>
                </Container>
                <div className="tabPanelContainer">
                    <Container>
                        <TabPanels>
                            <TabPanel key="pending" tab="pending">
                                <div className="pending-complete-tab-panels">
                                    <div className="my-requests-search-filter-view">
                                        <MyRequestsSearchFilterView placeHolderText="Search Requests (use at least 3 characters)" />
                                    </div>
                                    <Card>
                                        <MyRequestsDisplay
                                            myRequests={myRequests}
                                            totalPendingRequestCount={
                                                totalPendingRequestCount
                                            }
                                            totalCompleteRequestCount={
                                                totalCompleteRequestCount
                                            }
                                            rowsToDisplay={
                                                rowsToDisplay as DisplayAmount
                                            }
                                            currentPage={currentPage}
                                            onDisplayPageChange={
                                                onRowsToDisplayChange
                                            }
                                            goToPage={onPageChange}
                                            onSelectSortValue={
                                                onSelectSortValue
                                            }
                                            currentSortValue={sortValue}
                                        />
                                    </Card>
                                </div>
                            </TabPanel>
                            <TabPanel key="complete" tab="complete">
                                <div className="pending-complete-tab-panels">
                                    <div className="my-requests-search-filter-view">
                                        <SearchFilterView placeHolderText="Search Requests (use at least 3 characters)" />
                                    </div>
                                    <Card>
                                        <MyRequestsDisplay
                                            myRequests={myRequests}
                                            totalPendingRequestCount={
                                                totalPendingRequestCount
                                            }
                                            totalCompleteRequestCount={
                                                totalCompleteRequestCount
                                            }
                                            rowsToDisplay={
                                                rowsToDisplay as DisplayAmount
                                            }
                                            currentPage={currentPage}
                                            onDisplayPageChange={
                                                onRowsToDisplayChange
                                            }
                                            goToPage={onPageChange}
                                            onSelectSortValue={
                                                onSelectSortValue
                                            }
                                            currentSortValue={sortValue}
                                        />
                                    </Card>
                                </div>
                            </TabPanel>
                        </TabPanels>
                    </Container>
                </div>
            </Tabs>
        </div>
    );
};
