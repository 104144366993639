import * as msal from "@azure/msal-browser";
import { LogLevel } from "@cpchem/logging";

export interface ProvidedConfig {
    api: {
        ingestedTags: {
            url: string;
            scopes: string[];
        };
        myRequests: {
            url: string;
            scopes: string[];
        };
        myRequestTag: {
            url: string;
            scopes: string[];
        };
        filters: {
            url: string;
            scopes: string[];
        };
        tagMetadata: {
            url: string;
            scopes: string[];
        };
        lookups: {
            url: string;
            scopes: string[];
        };
    };
    msal: msal.Configuration;
    logging: {
        appInsights: {
            connectionString: string;
            sinkPageViews?: boolean;
            sinkLogsAtOrAboveLevel?: LogLevel;
        };
        logRocketAppId: string;
    };
    graph: {
        photo: {
            endpoint: string;
            scopes: string[];
        };
    };
    mocks?: {
        mockUserEndpoint: string;
    };
}

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}

export function getProvidedConfig(env?: string): ProvidedConfig {
    return env === "development"
        ? {
              api: {
                  filters: {
                      url: "https://api-dev.cpchem.com/hermesportal/v1/filters",
                      scopes: [
                          "https://api-dev.cpchem.com/HermesTags.FullAccess",
                          "User.Read"
                      ]
                  },
                  ingestedTags: {
                      url: "https://api-dev.cpchem.com/hermesportal/v1/ingestedtags",
                      scopes: [
                          "https://api-dev.cpchem.com/HermesTags.FullAccess",
                          "User.Read"
                      ]
                  },
                  lookups: {
                      url: "https://api-dev.cpchem.com/hermesportal/v1/Lookups",
                      scopes: [
                          "https://api-dev.cpchem.com/HermesTags.FullAccess",
                          "User.Read"
                      ]
                  },
                  myRequests: {
                      url: "https://api-dev.cpchem.com/hermesportal/v1/myRequests",
                      scopes: [
                          "https://api-dev.cpchem.com/HermesTags.FullAccess",
                          "User.Read"
                      ]
                  },
                  myRequestTag: {
                      url: "https://api-dev.cpchem.com/hermesportal/v1/myRequestTag",
                      scopes: [
                          "https://api-dev.cpchem.com/HermesTags.FullAccess",
                          "User.Read"
                      ]
                  },
                  tagMetadata: {
                      url: "https://api-dev.cpchem.com/hermesportal/v1/meta",
                      scopes: [
                          "https://api-dev.cpchem.com/HermesTags.FullAccess",
                          "User.Read"
                      ]
                  }
              },
              msal: {
                  auth: {
                      authority:
                          "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a",
                      clientId: "300c4eb9-038f-4035-91b5-8abf1792d915",
                      redirectUri: "https://localhost:3000/auth_redirect"
                  }
              },
              logging: {
                  appInsights: {
                      connectionString:
                          "InstrumentationKey=2a250b0d-1f94-4d6e-83f9-e395f48eabf6;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/;ApplicationId=8060a663-79f6-47ce-9c45-80f9337a1467",
                      sinkPageViews: true,
                      sinkLogsAtOrAboveLevel: LogLevel.ERROR
                  },
                  logRocketAppId: "cpchem/test"
              },
              graph: {
                  photo: {
                      endpoint:
                          "https://graph.microsoft.com/v1.0/me/photo/$value",
                      scopes: ["https://graph.microsoft.com/User.Read"]
                  }
              },
              mocks: {
                  mockUserEndpoint: "http://localHost:59850/getMockUser"
              }
          }
        : getConfigFromIndex();
}
