import { SearchBox } from "@cpchem/covalence-ui";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import { InitialFilterState } from "@services/hermes/interface";
import { TAG_NAME_FILTER } from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import { useService } from "../../service-provider";
import "./styles.scss";

type SearchableDropdownProps = {
    items: string[];
    onSelect: (item: string) => void;
};

export function SearchableDropdown({
    items,
    onSelect
}: SearchableDropdownProps) {
    const { state, dispatch } = useTagManagement();
    const { selectedSite, selectedSource } = state;
    const [searchTerm, setSearchTerm] = useState<string>(state.searchValue);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const hermesService = useService<HermesService>(HermesServiceKey);

    const handleSelect = (item: string) => {
        setSelectedItem(item);
        setSearchTerm(item);
        setSearchValueState(item);
        setIsDropdownOpen(false);
        onSelect(item);
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLLIElement>,
        item: string
    ) => {
        if (event.key === "Enter" || event.key === " ") {
            handleSelect(item);
        }
    };

    function onPressEnter() {
        console.log("On Press Enter search Term:" + `${searchTerm}`);
        removeTagLengthError();
        if (searchTerm.length >= 3) {
            setSearchValueState(searchTerm);
            getMatchingTagNames(searchTerm);
        } else {
            setSelectOptionsState(
                TAG_NAME_FILTER.TITLE,
                InitialFilterState.filterItems
            );
            setSelectedItem(null);
            setIsDropdownOpen(false);
            setErrorMessage("Search term should at least be three characters.");
            dispatch({
                type: "SET_IS_TAG_SEARCH_ERROR_DISPLAYED",
                payload: {
                    isTagSearchErrorDisplayed: true
                }
            });
        }
    }

    function onSearchValueChange(searchValue: string) {
        console.log("Search Value:" + `${searchValue}`);
        setSearchTerm(searchValue);
        setSearchValueState(searchValue);
        removeTagLengthError();
        if (searchValue.length >= 3) {
            getMatchingTagNames(searchValue);
        } else {
            setSelectOptionsState(
                TAG_NAME_FILTER.TITLE,
                InitialFilterState.filterItems
            );
            setSelectedItem(null);
            setIsDropdownOpen(false);
        }
    }

    function setSearchValueState(value: string) {
        const trimmedValue = value.trim();
        dispatch({ type: "SET_SEARCH_VALUE", payload: trimmedValue });
    }

    function removeTagLengthError() {
        setErrorMessage("");
        dispatch({
            type: "SET_IS_TAG_SEARCH_ERROR_DISPLAYED",
            payload: {
                isTagSearchErrorDisplayed: false
            }
        });
    }

    async function getMatchingTagNames(searchValue: string) {
        try {
            console.log("search Term:" + `${searchTerm}`);
            const tagNamesMetadataResponse =
                await hermesService.FilterTagNamesMetadata(
                    selectedSite,
                    selectedSource,
                    searchValue
                );

            if (tagNamesMetadataResponse.data) {
                const data = tagNamesMetadataResponse.data as string[];
                setSelectOptionsState(TAG_NAME_FILTER.TITLE, data);
                setIsDropdownOpen(true);
            }
        } catch (error) {
            setSelectOptionsState(
                TAG_NAME_FILTER.TITLE,
                InitialFilterState.filterItems
            );
        }
    }

    async function setSelectOptionsState(filterName: string, data: string[]) {
        const selectOptionActions = {
            [TAG_NAME_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_TAG_NAMES_FOR_SITE_AND_SOURCE",
                    payload: {
                        tagNamesForSiteAndSource: data
                    }
                });
            }
        };
        const action = selectOptionActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    return (
        <>
            <div className="tag-name-container">
                <div className="dropdown-container">
                    <SearchBox
                        label="Tag Name"
                        value={state.searchValue}
                        description=""
                        className="tag-name-search-box"
                        isDisabled={
                            selectedSource === "" ||
                            selectedSource === undefined
                        }
                        showLeftIcon
                        placeholder="Use at least 3 characters..."
                        onPressEnter={onPressEnter}
                        onChange={onSearchValueChange}
                        testId={NewTagsRequestTestIds.TagNameSearch}
                        errorMessage={errorMessage}
                    />
                    {isDropdownOpen && (
                        <ul
                            className="dropdown-list"
                            role="listbox"
                            data-testid={`${NewTagsRequestTestIds.TagNameListBox}`}
                        >
                            {items.map((item) => (
                                <li
                                    key={item}
                                    className={`dropdown-list-item ${
                                        item === selectedItem ? "selected" : ""
                                    }`}
                                    onClick={() => handleSelect(item)}
                                    onKeyDown={(e) => handleKeyDown(e, item)}
                                    role="option"
                                    aria-selected={item === selectedItem}
                                    tabIndex={0}
                                    data-testid={`${NewTagsRequestTestIds.TagNameListBox}-${item}`}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
}
