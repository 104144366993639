/* eslint-disable complexity */
import { Button, DataGridRow, Pill, Select } from "@cpchem/covalence-ui";
import { faMemoPad } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagReviewTestIds } from "@pages/tag-management-page/test-ids";
import { Change, Log, Tag } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import "./styles.scss";

interface RequestTagReviewRowProps {
    row: DataGridRow;
    updateTagRow: (
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined
    ) => void;
}

export const RequestTagReviewRow = ({
    row,
    updateTagRow
}: RequestTagReviewRowProps) => {
    const tag = row.values as Tag;
    const key = Number(row.id) + 1;
    const { state } = useTagManagement();
    const [isEditing, setIsEditing] = useState(false);
    const [editedTag, setEditedTag] = useState(tag);
    const [reasonForChange, setReasonForChange] = useState("");
    const [commentRequiredError, setCommentRequiredError] = useState(false);
    const isSwitchOn = state.removedTagsSwitchStatus === "On";
    const isTagRemoved = tag.tagStatus?.toLowerCase() === "removed";
    const confirmedTag =
        tag.tagStatus?.toLowerCase() === "confirmed" ||
        tag.tagStatus?.toLowerCase() === "complete";
    let showStatusColumn;
    let tagRowStyle = "";
    let memoPadStyle = "";
    if (isSwitchOn && isTagRemoved) {
        showStatusColumn = (
            <td className="tag-status">
                <Pill
                    variant="outline"
                    text={"Removed"}
                    color="info"
                    size="small"
                />
            </td>
        );
    } else if (confirmedTag) {
        showStatusColumn = (
            <td className="tag-status">
                {" "}
                <Pill
                    variant="outline"
                    text={"Confirmed"}
                    color="info"
                    size="small"
                />
            </td>
        );
    } else {
        showStatusColumn = <td className="tag-status">{}</td>;
    }

    if (isSwitchOn && isTagRemoved) {
        tagRowStyle = "tag-review-row-removed";
    } else if (isEditing) {
        tagRowStyle = "tag-review-row-edited";
    } else {
        tagRowStyle = "tag-review-row-pending";
    }

    memoPadStyle =
        tag.commentCount > 0 ? "memo-pad-enabled" : "memo-pad-disabled";

    const saveChanges = () => {
        if (
            editedTag.pullFrequency !== tag.pullFrequency &&
            reasonForChange.length === 0
        ) {
            setCommentRequiredError(true);
        } else {
            const tagChanges: Change[] = [];
            if (editedTag.pullFrequency !== tag.pullFrequency) {
                const pullFreqChange: Change = {
                    name: "pullFrequency",
                    from: tag.pullFrequency,
                    to: editedTag.pullFrequency
                };
                tagChanges.push(pullFreqChange);
            }
            const tagLog: Log = {
                reasonForChange: reasonForChange,
                tagUpdates: tagChanges
            };
            updateTagRow(editedTag.tagName, editedTag.pullFrequency, tagLog);
            setIsEditing(false);
            setCommentRequiredError(false);
        }
    };

    const textAreaStyle: React.CSSProperties = commentRequiredError
        ? { borderColor: "var(--danger)" }
        : { borderColor: "#767676" };

    const cancelEdit = () => {
        setCommentRequiredError(false);
        setIsEditing(false);
        setEditedTag(tag);
    };

    const handleEditChange = (field: string, value: string) => {
        if (
            field === "reasonForChange" &&
            editedTag.pullFrequency !== tag.pullFrequency
        ) {
            if (value) {
                setReasonForChange(value);
                setCommentRequiredError(false);
            } else {
                setCommentRequiredError(true);
            }
        } else if (
            field === "reasonForChange" &&
            editedTag.pullFrequency === tag.pullFrequency
        ) {
            if (value) {
                setReasonForChange(value);
                setCommentRequiredError(false);
            }
        }
        setEditedTag((prev) => ({ ...prev, [field]: value }));
    };

    function openTagActivityPopup() {
        return <>Some Popup</>;
    }

    return (
        <>
            <tr key={key} className={tagRowStyle}>
                <td className="items-cell">{tag.site}</td>
                <td className="items-cell">{tag.source}</td>
                {!isEditing && !confirmedTag && !isTagRemoved && (
                    <td className="items-cell">
                        <span
                            onClick={() => setIsEditing(!isEditing)}
                            onKeyDown={() => setIsEditing(!isEditing)}
                            role="button"
                            tabIndex={0}
                            className="tag-name-link"
                            data-testid={`${TagReviewTestIds.EditTagButton}-${tag.tagName}`}
                        >
                            {tag.tagName}
                        </span>
                    </td>
                )}
                {!isEditing && (confirmedTag || isTagRemoved) && (
                    <td className="items-cell">{tag.tagName}</td>
                )}
                {isEditing && (
                    <td className="items-cell">
                        <span
                            onClick={() => setIsEditing(!isEditing)}
                            onKeyDown={() => setIsEditing(!isEditing)}
                            role="button"
                            tabIndex={0}
                            data-testid={`${TagReviewTestIds.EditTagButton}-${tag.tagName}`}
                        >
                            {tag.tagName}
                        </span>
                    </td>
                )}
                <td className="items-cell">{tag.engUnits}</td>
                <td className="items-cell">{tag.sourceFrequency}</td>
                <td className="items-cell">{tag.aggregationType}</td>
                {isEditing && (
                    <td className="items-cell">
                        <Select
                            value={editedTag.pullFrequency || tag.pullFrequency}
                            options={state.pullFrequencySelectOptions}
                            className="select-dropdown"
                            onChange={(pullFrequency) =>
                                handleEditChange("pullFrequency", pullFrequency)
                            }
                            testId={`${TagReviewTestIds.PullFrequencySelect}-${tag.tagName}`}
                            isLabelHidden
                            label={""}
                        />
                    </td>
                )}
                {!isEditing && (
                    <td className="items-cell">{tag.pullFrequency}</td>
                )}
                <td className="items-cell">{tag.frequencyMode}</td>
                <td>
                    {
                        <FontAwesomeIcon
                            icon={faMemoPad}
                            className={memoPadStyle}
                            size="sm"
                            onClick={openTagActivityPopup}
                            data-testid={`${TagReviewTestIds.MemoPadTagIcon}-${tag.tagName}`}
                        />
                    }{" "}
                    {tag.commentCount > 0 ? tag.commentCount : ""}
                </td>
                {showStatusColumn}
            </tr>
            {isEditing && (
                <tr className="edit-container-row">
                    <td colSpan={10}>
                        <div className="edit-container">
                            <div className="edit-tag-text-area-container">
                                <div className="edit-tag-text-area-panel">
                                    <p>Reason For Change</p>
                                    {commentRequiredError && (
                                        <p
                                            className="comment-required"
                                            data-testid={`${TagReviewTestIds.ReasonRequired}-${tag.tagName}`}
                                        >
                                            &nbsp; *Required
                                        </p>
                                    )}
                                </div>
                                <textarea
                                    placeholder="Enter Reason For Change Here..."
                                    style={textAreaStyle}
                                    title="Reason for change"
                                    onChange={(e) =>
                                        handleEditChange(
                                            "reasonForChange",
                                            e.target.value
                                        )
                                    }
                                    className="edit-tag-text-area"
                                />
                            </div>
                            <div className="edit-tag-panel-buttons">
                                <Button
                                    variant="outline"
                                    size="medium"
                                    text="Cancel"
                                    className="cancel-tag-edit"
                                    color="secondary"
                                    onClick={cancelEdit}
                                    testId={`${TagReviewTestIds.CancelTagEdit}-${tag.tagName}`}
                                />
                                <Button
                                    variant="solid"
                                    size="medium"
                                    text="Save"
                                    className="save-tag-edit"
                                    color="primary"
                                    onClick={saveChanges}
                                    testId={`${TagReviewTestIds.SaveTagEdit}-${tag.tagName}`}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};
