import { TagRequest, UpdateTag } from "@services/hermes/model";
import { ApiResponse } from "../api-response";
import {
    IngestedTagsData,
    MyApprovalsData,
    MyRequestsData,
    Tag
} from "./model";

export const ServiceKey = "HermesService";

export const InitialIngestedTagsDataState: IngestedTagsData = {
    id: 0,
    startPage: 0,
    endPage: 0,
    pageSize: 0,
    totalCount: 0,
    hadNextPage: false,
    hadPrevPage: false,
    items: []
};

export const InitialMyRequestsDataState: MyRequestsData = {
    id: 0,
    startPage: 0,
    endPage: 0,
    pageSize: 0,
    totalPendingCount: 0,
    totalCompleteCount: 0,
    hadNextPage: false,
    hadPrevPage: false,
    items: []
};

export const InitialMyApprovalsDataState: MyApprovalsData = {
    id: 0,
    startPage: 0,
    endPage: 0,
    pageSize: 0,
    totalPendingCount: 0,
    totalCompleteCount: 0,
    hadNextPage: false,
    hadPrevPage: false,
    items: []
};

export const InitialFilterState: GetTagFiltersAPIResponse = {
    filterItems: []
};

export type GetIngestedTagsAPIRequest = {
    start: number;
    end: number;
    pageSize: number;
    orderBy: string;
    desc: boolean;
    siteFilters: string[];
    sourceSystemFilters: string[];
    sourceFrequencyFilters: string[];
    aggregationTypeFilters: string[];
    statusFilters: string[];
};

export type SearchIngestedTagsAPIRequest = {
    fieldName: string;
    value: string;
    start: number;
    end: number;
    pageSize: number;
    orderBy: string;
    desc: boolean;
    siteFilters: string[];
    sourceSystemFilters: string[];
    sourceFrequencyFilters: string[];
    aggregationTypeFilters: string[];
    statusFilters: string[];
};

export type GetIngestedTagsAPIResponse = {
    ingestedTagsData: IngestedTagsData;
};

export type GetTagFiltersAPIResponse = {
    filterItems: string[];
};

export type GetMyRequestsAPIRequest = {
    start: number;
    end: number;
    pageSize: number;
    orderBy: string;
    desc: boolean;
    siteFilters: string[];
    sourceSystemFilters: string[];
    myRequestsStatus: string;
};

export type GetMyApprovalsAPIRequest = {
    start: number;
    end: number;
    pageSize: number;
    orderBy: string;
    desc: boolean;
    siteFilters: string[];
    sourceSystemFilters: string[];
    myApprovalsStatus: string;
};

export type SearchMyRequestsAPIRequest = {
    fieldName: string;
    value: string;
    start: number;
    end: number;
    pageSize: number;
    orderBy: string;
    desc: boolean;
    siteFilters: string[];
    sourceSystemFilters: string[];
    myRequestsStatus: string;
};

export type SearchMyApprovalsAPIRequest = {
    fieldName: string;
    value: string;
    start: number;
    end: number;
    pageSize: number;
    orderBy: string;
    desc: boolean;
    siteFilters: string[];
    sourceSystemFilters: string[];
    myApprovalsStatus: string;
};

export type CreateNewTagsAPIResponse = {
    code: number;
    data: {
        id: number;
    };
    message?: string;
};

// export type ValidateAndGetTagsMetadataAPIResponse = {
//     code: number;
//     data: [];
//     message?: string;
// };

export type TagActionsAPIResponse = {
    code: number;
    data: [];
    message?: string;
};

export type RequestTagAPIResponse = {
    code: number;
    message?: string;
    data: TagRequest;
};

export interface HermesService {
    /** Get configured Tags from Tag Ingestion Database */
    GetIngestedTags(
        ingestedTagsRequest: GetIngestedTagsAPIRequest
    ): Promise<ApiResponse<IngestedTagsData>>;

    /** Get Ingested tags for searched term */
    SearchIngestedTags(
        searchRequest: SearchIngestedTagsAPIRequest
    ): Promise<ApiResponse<IngestedTagsData>>;

    /** Get filter items for the filter ID */
    GetFilterItemsForFilterID(filterID: string): Promise<ApiResponse<string[]>>;

    /** Get my requests from Tag Requests Database */
    GetMyRequests(
        myRequestsRequest: GetMyRequestsAPIRequest
    ): Promise<ApiResponse<MyRequestsData>>;

    /** Get my approvals from Tag Requests Database */
    GetMyApprovals(
        myApprovalsRequest: GetMyApprovalsAPIRequest
    ): Promise<ApiResponse<MyApprovalsData>>;

    /** Get requests for searched term */
    SearchMyRequests(
        searchRequest: SearchMyRequestsAPIRequest
    ): Promise<ApiResponse<MyRequestsData>>;

    /** Get approvals for searched term */
    SearchMyApprovals(
        searchApproval: SearchMyApprovalsAPIRequest
    ): Promise<ApiResponse<MyApprovalsData>>;

    // Get metadata for site dropdown in the New Tag Request Page
    GetSiteMetadata(): Promise<ApiResponse<string[]>>;

    // Get metadata for source system dropdown in the New Tag Request Page
    GetSourceSystemMetadata(
        site: string | undefined
    ): Promise<ApiResponse<string[]>>;

    // Get metadata for Tag Name dropdown in the New Tag Request Page
    GetTagNameMetadata(
        site: string | undefined,
        source: string | undefined
    ): Promise<ApiResponse<string[]>>;

    // Get matching tag names for filter term
    FilterTagNamesMetadata(
        site: string | undefined,
        source: string | undefined,
        filterTerm: string | undefined
    ): Promise<ApiResponse<string[]>>;

    GetNewTagMetadata(newTag: Tag): Promise<ApiResponse<Tag>>;

    // Get lookup data for frequencyMode or pullFrequency
    GetLookupData(lookupType: string): Promise<ApiResponse<string[]>>;

    // Submit New tags Request
    CreateNewTagsRequest(
        tagRequest: TagRequest
    ): Promise<CreateNewTagsAPIResponse>;

    ValidateAndGetTagsMetadata(
        site: string | undefined,
        source: string | undefined,
        tags: string[]
    ): Promise<TagActionsAPIResponse>;

    /** Get request details */
    GetRequest(requestId: number): Promise<ApiResponse<RequestTagAPIResponse>>;

    RemoveTag(
        requestId: number,
        tagId: number,
        status: string,
        reasonForChange: string
    ): Promise<TagActionsAPIResponse>;

    UpdateTag(updateTag: UpdateTag): Promise<TagActionsAPIResponse>;

    ChangeTagStatus(
        requestId: number,
        tagId: number,
        status: string
    ): Promise<TagActionsAPIResponse>;

    ConfirmAllTags(
        requestId: number,
        status: string
    ): Promise<TagActionsAPIResponse>;

    ApproveRequest(
        requestId: number,
        status: string
    ): Promise<TagActionsAPIResponse>;
}
