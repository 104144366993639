import { RequestTitleViewTestIds } from "@pages/tag-management-page/test-ids";
import React from "react";
import HermesAccordion from "../../../../app/components/hermes-accordion";
import "./styles.scss";
interface RequestTitleProps {
    title?: string;
    status: string;
    lastUpdated?: string;
}
export const RequestTitleView = ({
    title,
    status,
    lastUpdated
}: RequestTitleProps) => {
    return (
        <div data-testid={RequestTitleViewTestIds.HermesAccordion}>
            <HermesAccordion
                title={title}
                status={status}
                lastUpdated={lastUpdated}
            >
                <p>
                    These Tags need approval before we can start using them for
                    the Sweeny Project. Please discuss both internally and
                    externally if these tags meet requirements. If not, please
                    go ahead make changes accordingly so we can start utilizing
                    them as soon as possible. If you have any questions, feel
                    free to contact me.
                </p>
            </HermesAccordion>
        </div>
    );
};
