import { ConfirmToggle } from "@components/display/confirm-toggle/confirm-toggle";
import { ApprovalTagsReviewDisplayTestIds } from "@pages/tag-management-page/test-ids";
import { useTagManagement } from "@stores/tag-management-context";
import React from "react";

export function ApprovalTagReviewHeaderRenderer(): JSX.Element {
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const requestApproved =
        tagRequest.requestStatus?.toLowerCase() === "approved";
    const isSwitchOn = state.removedTagsSwitchStatus === "On";

    function allCheckBoxStateChanged(checked: boolean) {
        if (checked) {
            dispatch({
                type: "SET_CONFIRM_ALL",
                payload: {
                    confirmAll: "confirmed"
                }
            });
        } else {
            dispatch({
                type: "SET_CONFIRM_ALL",
                payload: {
                    confirmAll: "pending"
                }
            });
        }
    }
    const isCheckedState = state.confirmAll?.toLowerCase() === "confirmed";
    return (
        <thead className="is-sticky">
            <tr>
                <th>
                    <span className="header-cell justify-left site">Site</span>
                </th>
                <th>
                    <span className="header-cell justify-left source">
                        Source System
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left tagName">
                        Tag Name
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left engUnits">
                        Eng Units
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left sourceFrequency">
                        Source Frequency
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left aggregationType">
                        Aggregation Type
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left pullFrequency">
                        Pull Frequency
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left frequencyMode">
                        Frequency Mode
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left" />
                </th>
                <th>
                    <span className="header-cell justify-center tagStatus">
                        {!isSwitchOn && (
                            <ConfirmToggle
                                isChecked={isCheckedState}
                                isDisabled={requestApproved}
                                onChange={allCheckBoxStateChanged}
                                testId={
                                    ApprovalTagsReviewDisplayTestIds.ConfirmAllToggle
                                }
                            />
                        )}
                    </span>
                </th>
            </tr>
        </thead>
    );
}
