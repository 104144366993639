/* eslint-disable react-hooks/exhaustive-deps */
import { DataGridColumnSortState } from "@components/display/base-header-display/constants";
import { Button } from "@cpchem/covalence-ui";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { MyRequestsTestIds } from "@pages/tag-management-page/test-ids";
import { HermesServiceKey } from "@services";
import {
    GetMyRequestsAPIRequest,
    HermesService,
    InitialFilterState,
    InitialMyRequestsDataState,
    SearchMyRequestsAPIRequest
} from "@services/hermes/interface";
import { MyRequestsData } from "@services/hermes/model";
import {
    FilterType,
    SITE_FILTER,
    SOURCE_SYSTEM_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { buildFilterListItemList } from "@utilities/filters/build-filter-list-item-list";
import { getSelectedFiltersFromList } from "@utilities/filters/set-selected-filters-from-list-on-load";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../../../../app/route-paths";
import { useService } from "../../../../../service-provider";
import { RequestsTableView } from "../requests-table-view";

export function MyRequestsTableView(): JSX.Element {
    const navigate = useNavigate();
    const { state, dispatch } = useTagManagement();
    const hermesService = useService<HermesService>(HermesServiceKey);
    const myRequestsRequest: GetMyRequestsAPIRequest = {
        start: state.currentPage,
        end: state.currentPage,
        pageSize: state.rowsToDisplay,
        siteFilters: getSelectedFiltersFromList(state.siteFilters),
        sourceSystemFilters: getSelectedFiltersFromList(
            state.sourceSystemFilters
        ),
        myRequestsStatus: state.myRequestsStatus,
        orderBy: state.sortValue.column.toLowerCase(),
        desc: state.sortValue.sort === DataGridColumnSortState.DESC
    };
    const searchMyRequestsRequest: SearchMyRequestsAPIRequest = {
        fieldName: state.searchFieldName || "requestName",
        value: state.searchValue,
        start: state.currentPage,
        end: state.currentPage,
        pageSize: state.rowsToDisplay,
        siteFilters: getSelectedFiltersFromList(state.siteFilters),
        sourceSystemFilters: getSelectedFiltersFromList(
            state.sourceSystemFilters
        ),
        myRequestsStatus: state.myRequestsStatus,
        orderBy: state.sortValue.column.toLowerCase() || "site",
        desc: state.sortValue.sort === DataGridColumnSortState.DESC
    };
    const { isLoading, onSetLoading } = useTagManagementContext();
    async function getMyRequests() {
        if (state.sortValue.sort === DataGridColumnSortState.WAIT) return;
        try {
            let response;
            onSetLoading(true);

            if (!state.searchValue) {
                response = await hermesService.GetMyRequests(myRequestsRequest);
            } else {
                response = await hermesService.SearchMyRequests(
                    searchMyRequestsRequest
                );
            }

            if (response.data) {
                const data = response.data as MyRequestsData;
                setMyRequestsState(data);
            }
        } catch (error) {
            setMyRequestsState(InitialMyRequestsDataState);
        } finally {
            onSetLoading(false);
        }
    }
    function setMyRequestsState(data: MyRequestsData) {
        dispatch({
            type: "SET_MY_REQUESTS_DATA",
            payload: {
                totalPendingRequestCount: data.totalPendingCount,
                totalCompleteRequestCount: data.totalCompleteCount,
                myRequests: data.items
            }
        });
    }

    async function setFiltersState(filterName: string, data: string[]) {
        const filterActions = {
            [SITE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SITE_FILTER",
                    payload: {
                        siteFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.siteFilters ?? []
                        )
                    }
                });
            },
            [SOURCE_SYSTEM_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SOURCE_SYSTEM_FILTER",
                    payload: {
                        sourceSystemFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.sourceSystemFilters ?? []
                        )
                    }
                });
            }
        };

        const action = filterActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    async function getFilterItems(filterType: FilterType) {
        try {
            const filterResponse =
                await hermesService.GetFilterItemsForFilterID(filterType.ID);

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setFiltersState(filterType.TITLE, data);
            }
        } catch (error) {
            setFiltersState(filterType.TITLE, InitialFilterState.filterItems);
        }
    }

    async function getAllInitialFilterItems() {
        getFilterItems(SITE_FILTER);
        getFilterItems(SOURCE_SYSTEM_FILTER);
    }

    useEffect(() => {
        getAllInitialFilterItems();
    }, []);

    useEffect(() => {
        getMyRequests();
    }, [
        state.currentPage,
        state.rowsToDisplay,
        state.sortValue,
        state.searchFieldName,
        state.searchValue,
        state.siteFilters,
        state.sourceSystemFilters,
        state.myRequestsStatus,
        dispatch
    ]);

    return (
        <div className="my-requests-table-view">
            <div className="my-requests-table">
                <div className="my-requests">
                    <div className="header" data-testid="">
                        <p className="title" data-testid="">
                            My Requests
                        </p>
                        <div className="new-request-button">
                            <Button
                                variant="solid"
                                text="New Request"
                                color="primary"
                                onClick={() => navigate(routePath.NEW_REQUEST)}
                                testId={MyRequestsTestIds.NewRequestButton}
                            />
                        </div>
                    </div>
                    {!isLoading && <RequestsTableView />}
                </div>
            </div>
        </div>
    );
}
