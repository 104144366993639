import { DataGridRow } from "@cpchem/covalence-ui";
import { TagRequest } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React from "react";
import { Link } from "react-router-dom";

export function ResultRowFull(row: DataGridRow): JSX.Element {
    const tagRequestsInfo = row.values as TagRequest;
    const { state } = useTagManagement();
    const { myApprovals } = state;
    const rowRequestDetails = myApprovals.find(
        (request) => request.id === tagRequestsInfo.id
    )?.requestDetails;
    return (
        <tr key={`${row.id}`}>
            {GetBaseColumnsRow(tagRequestsInfo, rowRequestDetails)}
        </tr>
    );
}

export function FilteredEmptyResults() {
    return (
        <div className="no-results">
            <div className="no-results-content">
                <div className="no-results-title">
                    No Tag Approvals match the current <b>Search Term</b> and/or{" "}
                    <b>Filters</b>.
                </div>
                <div className="no-results-subtitle">
                    Expecting to see Tag Approvals? Make sure the{" "}
                    <b>Search Term</b> & <b>Filters</b> are applied correctly.
                </div>
            </div>
        </div>
    );
}

function GetBaseColumnsRow(
    row: TagRequest,
    rowRequestDetails: string | undefined
): JSX.Element {
    const { requestedDate } = row;
    const requestedDateString = requestedDate
        ? new Date(requestedDate).toLocaleDateString()
        : "";
    return (
        <>
            <td className="items-cell left">
                <span className="orders-link">
                    <Link
                        to={`/tag-management/my-approvals/approval-preview/${row.id}`}
                        state={row.id}
                    >
                        {row.id}
                    </Link>
                </span>
            </td>
            <td className="items-cell left">
                {row.requestName}
                <br />
                <label style={{ fontSize: ".8em", color: "gray" }}>
                    {rowRequestDetails}
                </label>
            </td>
            <td className="items-cell left">{row.site}</td>
            <td className="items-cell left">{row.source}</td>
            <td className="items-cell left">{row.requestedBy}</td>
            <td className="items-cell left">{requestedDateString}</td>
        </>
    );
}
