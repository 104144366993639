import { RequestTagReviewDisplay } from "@components/display/request-displays/request-tag-review/request-tag-review-display";
import { Card, Modal, ModalContent, useToast } from "@cpchem/covalence-ui";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { RequestPreviewTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import {
    InitialFilterState,
    RequestTagAPIResponse
} from "@services/hermes/interface";
import { Log, TagRequest } from "@services/hermes/model";
import {
    FilterType,
    PULL_FREQUENCY_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { capitalizeFirstLetter } from "@utilities/capitalize-first-letter";
import { buildSelectOptionsList } from "@utilities/filters/build-select-options-list";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useService } from "../../../../../service-provider";
import { RequestTitleView } from "../../request-title-view";
import "./styles.scss";

export function RequestPreview(): JSX.Element {
    const { requestId } = useParams();
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { isLoading, onSetLoading } = useTagManagementContext();
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const { createToast } = useToast();

    async function getRequestDetails() {
        try {
            onSetLoading(true);
            const response = await hermesService.GetRequest(Number(requestId));
            if (response.data) {
                const tagActionsResponse =
                    response.data as RequestTagAPIResponse;
                const results = tagActionsResponse.data as TagRequest;
                setTagRequestDataState(results);
            }
        } catch (error) {
            console.error(error);
        } finally {
            onSetLoading(false);
        }
    }

    function onSuccessModalClosed() {
        setSuccessModalVisible(false);
    }

    const handleUpdateTagRow = async (
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined
    ) => {
        try {
            onSetLoading(true);
            const tagReviewed = tagRequest.items.find(
                (tag) => tag.tagName === tagName
            );

            if (tagReviewed) {
                const updatedTag = {
                    ...tagReviewed,
                    pullFrequency: pullFrequency,
                    comments: comments
                };

                await hermesService
                    .UpdateTag({
                        ...updatedTag,
                        ...{ requestId: tagRequest.id }
                    })
                    .then(async (updateTagResponse) => {
                        if (updateTagResponse.code === 200) {
                            await hermesService
                                .GetRequest(Number(requestId))
                                .then((getRequestDetailsResponse) => {
                                    if (getRequestDetailsResponse.data) {
                                        const tagActionsResponse =
                                            getRequestDetailsResponse.data as RequestTagAPIResponse;
                                        const results =
                                            tagActionsResponse.data as TagRequest;
                                        setTagRequestDataState(results);

                                        createToast(
                                            `Tag ${tagName} updated successfully!`,
                                            {
                                                id: `tag-${tagName}-updated`,
                                                severity: "info"
                                            }
                                        );
                                    }
                                });
                        } else {
                            const errorMessage = updateTagResponse.message
                                ? updateTagResponse.message
                                : "Unknown error occurred, please contact EHFIL Dev Support Group";
                            throw new Error(errorMessage);
                        }
                    });
            } else {
                throw new Error(
                    "Unknown error occurred, please contact EHFIL Dev Support Group"
                );
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            createToast(
                `Tag ${tagName} update unsuccessful. ${error.message}`,
                {
                    id: `tag-${tagName}-not-updated`,
                    severity: "danger",
                    autoDismiss: false,
                    autoDismissDelay: 15000
                }
            );
        } finally {
            onSetLoading(false);
        }
    };

    function setTagRequestDataState(updatedTagRequest: TagRequest) {
        dispatch({
            type: "SET_TAG_REQUEST_DATA",
            payload: {
                tagRequest: { ...updatedTagRequest }
            }
        });
    }

    async function getLookupItems(filterType: FilterType) {
        try {
            const filterResponse = await hermesService.GetLookupData(
                filterType.ID
            );

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setSelectOptionsState(filterType.TITLE, data);
            }
        } catch (error) {
            setSelectOptionsState(
                filterType.TITLE,
                InitialFilterState.filterItems
            );
        }
    }

    async function setSelectOptionsState(filterName: string, data: string[]) {
        const selectOptionActions = {
            [PULL_FREQUENCY_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_PULL_FREQUENCY_SELECT_OPTIONS",
                    payload: {
                        pullFrequencySelectOptions: buildSelectOptionsList(
                            PULL_FREQUENCY_FILTER.TITLE,
                            data
                        )
                    }
                });
            }
        };

        const action = selectOptionActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    async function getAllInitialLookupItems() {
        getLookupItems(PULL_FREQUENCY_FILTER);
    }

    useEffect(() => {
        getRequestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, dispatch]);

    useEffect(() => {
        getAllInitialLookupItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="request-preview">
            <Card testId={RequestPreviewTestIds.RequestTitleViewCard}>
                <RequestTitleView
                    title={tagRequest.requestName}
                    status={capitalizeFirstLetter(tagRequest.requestStatus)}
                    lastUpdated="Last updated 20 mins ago"
                />
            </Card>
            {!isLoading && (
                <Card
                    testId={RequestPreviewTestIds.RequestTagsReviewDisplayCard}
                >
                    <RequestTagReviewDisplay
                        updateTagRow={handleUpdateTagRow}
                    />
                </Card>
            )}
            <Modal
                onRequestClose={onSuccessModalClosed}
                title="Success"
                isOpen={successModalVisible}
                className="success-modal"
                testId={RequestPreviewTestIds.SuccessModal}
            >
                <ModalContent
                    testId={RequestPreviewTestIds.SuccessModalContent}
                >
                    <div className="success-modal-question">
                        Action Successful!
                    </div>
                    <div className="success-modal-text">
                        Modal opened successfully!
                    </div>
                </ModalContent>
            </Modal>
        </div>
    );
}
