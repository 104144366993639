import React from "react";

export function RequestTagReviewHeaderRenderer(): JSX.Element {
    return (
        <thead className="is-sticky">
            <tr>
                <th>
                    <span className="header-cell justify-left site">Site</span>
                </th>
                <th>
                    <span className="header-cell justify-left source">
                        Source System
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left tagName">
                        Tag Name
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left engUnits">
                        Eng Units
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left sourceFrequency">
                        Source Frequency
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left aggregationType">
                        Aggregation Type
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left pullFrequency">
                        Pull Frequency
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left frequencyMode">
                        Frequency Mode
                    </span>
                </th>
                <th>
                    <span className="header-cell justify-left" />
                </th>
                <th>
                    <span className="header-cell justify-left">Status</span>
                </th>
            </tr>
        </thead>
    );
}
