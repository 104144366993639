import { DataGrid, DataGridProps, Switch } from "@cpchem/covalence-ui";
import { ApprovalTagsReviewDisplayTestIds } from "@pages/tag-management-page/test-ids";
import { Log, Tag } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import { baseColumns } from "./renderer/base-columns";
import { ApprovalTagReviewHeaderRenderer } from "./renderer/header";
import { ApprovalTagReviewRow } from "./renderer/row";
import "./styles.scss";

interface ApprovalTagReviewDisplayProps {
    updateTagRow: (
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined,
        frequencyMode?: string | undefined
    ) => void;
    removeTagRow: (
        tagName: string | undefined,
        tagStatus: string | null,
        reasonForChange: string
    ) => void;
    changeTagRowStatus: (
        tagName: string | undefined,
        tagStatus: string | null
    ) => void;
}

export function ApprovalTagReviewDisplay({
    removeTagRow,
    updateTagRow,
    changeTagRowStatus
}: ApprovalTagReviewDisplayProps) {
    const { state, dispatch } = useTagManagement();
    const { tagRequest } = state;
    const [switchStatus, setSwitchStatus] = useState(
        state.removedTagsSwitchStatus
    );
    const isSwitchOn = switchStatus === "On";
    let allTagsToReview: Tag[] = [];
    let notRemovedTagsToReview: Tag[] = [];

    if (tagRequest.items) {
        allTagsToReview = tagRequest.items;
        notRemovedTagsToReview = tagRequest.items.filter(
            (tag) => tag.tagStatus?.toLowerCase() !== "removed"
        );
    }
    let showTagsToReview;
    if (isSwitchOn) {
        showTagsToReview = allTagsToReview;
    } else {
        showTagsToReview = notRemovedTagsToReview;
    }

    function handleEmptyResults() {
        return (
            <div className="tag-review-grid-no-results">
                Unknown error occurred, please contact EHFIL Dev Support Group.
            </div>
        );
    }

    function toggleSwitch(checked: boolean) {
        if (checked) {
            setSwitchStatus("On");
            setRemovedTagsSwitchState("On");
        } else {
            setSwitchStatus("Off");
            setRemovedTagsSwitchState("Off");
        }
    }

    function setRemovedTagsSwitchState(statusText: string) {
        dispatch({
            type: "SET_REMOVED_TAGS_SWITCH_STATUS",
            payload: {
                removedTagsSwitchStatus: statusText
            }
        });
    }

    const gridProps: DataGridProps = {
        data: showTagsToReview,
        columns: baseColumns,
        useStickyHeaders: true,
        headerRenderer: () => <ApprovalTagReviewHeaderRenderer />,
        rowRenderer: (row) => (
            <ApprovalTagReviewRow
                key={row.id}
                row={row}
                updateTagRow={updateTagRow}
                removeTagRow={removeTagRow}
                changeTagRowStatus={changeTagRowStatus}
            />
        ),
        emptyResultsRenderer: () => handleEmptyResults()
    };

    return (
        <div className="approval-tag-review-grid">
            <div className="approval-tag-review-table-header">
                <p className="approval-tag-review-table-heading">Tag Review</p>
                <div className="approval-tag-review-table-switch">
                    <Switch
                        isChecked={isSwitchOn}
                        label="Show Removed Tags"
                        onChange={toggleSwitch}
                        testId={ApprovalTagsReviewDisplayTestIds.StatusToggle}
                    />
                    <p
                        className="approval-tag-review-switch-status"
                        data-testid={
                            ApprovalTagsReviewDisplayTestIds.StatusToggleText
                        }
                    >
                        {switchStatus}
                    </p>
                </div>
            </div>
            <DataGrid
                key={showTagsToReview.length}
                {...gridProps}
                testId={ApprovalTagsReviewDisplayTestIds.DataGrid}
            />
        </div>
    );
}
