import { NewRequestTagsDisplay } from "@components/display/request-displays/new-tags-request/new-tags-request-display";
import { useAuth } from "@cpchem/azure-auth-react";
import {
    AlertOptions,
    Button,
    Card,
    Modal,
    ModalActions,
    ModalContent,
    useAlertBanner
} from "@cpchem/covalence-ui";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { NewTagsRequestTestIds } from "@pages/tag-management-page/test-ids";
import { HermesService, HermesServiceKey } from "@services";
import { TagRequest } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../../../../app/route-paths";
import { useService } from "../../../../../service-provider";
import { NewRequestDetailsView } from "./new-request-details-view";
import { NewRequestSelectOptionsView } from "./new-request-select-options-view";
import { NewRequestTitleView } from "./new-request-title-view";
import "./styles.scss";

interface CreateNewRequestViewProps {
    showInfoMessage?: boolean;
}

export function CreateNewRequestView({
    showInfoMessage
}: CreateNewRequestViewProps): JSX.Element {
    const { onSetLoading } = useTagManagementContext();
    const hermesService = useService<HermesService>(HermesServiceKey);
    const { currentAccount } = useAuth({});
    const { state } = useTagManagement();
    const { tagRequest } = state;
    const { createAlertBanner, dismissAlertBanner } = useAlertBanner();
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const navigate = useNavigate();

    function handleSubmitClick() {
        const currentDate = new Date();
        tagRequest.requestStatus = "pending";
        tagRequest.requestedBy = currentAccount?.name;
        tagRequest.requestedDate = currentDate.toISOString();
        tagRequest.requestedByEmail = currentAccount?.personalDetails?.email;
        dismissAlertBanner();
        if (tagRequest.requestName.length === 0) {
            const alertOptions: AlertOptions = {
                message: "Please enter a Request Name to submit this request",
                severity: "info",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } else if (tagRequest.requestReason?.length === 0) {
            const alertOptions: AlertOptions = {
                message:
                    "Please enter a Business Justification to submit this request",
                severity: "info",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } else {
            createTagRequest(tagRequest);
        }
    }

    async function createTagRequest(tagRequest: TagRequest) {
        try {
            onSetLoading(true);
            const newTagsRequest =
                await hermesService.CreateNewTagsRequest(tagRequest);

            if (newTagsRequest.code === 200) {
                setSuccessModalVisible(true);
            } else {
                const errorMessage = newTagsRequest.message
                    ? newTagsRequest.message
                    : "Unknown error occurred, please contact EHFIL Dev Support Group";
                throw new Error(errorMessage);
            }
        } catch (tagProcessingError) {
            const error = tagProcessingError as Error;
            const alertOptions: AlertOptions = {
                message: error.message,
                severity: "danger",
                dismissable: true
            };
            createAlertBanner(alertOptions);
        } finally {
            onSetLoading(false);
        }
    }

    function onSuccessModalClosed() {
        setSuccessModalVisible(false);
        navigate(routePath.MY_REQUESTS);
    }

    return (
        <div className="create-new-request-view">
            {showInfoMessage && <NewRequestTitleView />}
            <NewRequestSelectOptionsView />
            <NewRequestDetailsView />
            <Card>
                <NewRequestTagsDisplay />
            </Card>
            <div className="submit-request-container">
                <Button
                    variant="solid"
                    text="Submit Request"
                    className="submit-request-button"
                    color="primary"
                    onClick={handleSubmitClick}
                    isDisabled={tagRequest.items?.length === 0}
                    testId={NewTagsRequestTestIds.SubmitButton}
                />
            </div>
            <Modal
                onRequestClose={onSuccessModalClosed}
                title="Success"
                isOpen={successModalVisible}
                className="success-modal"
                testId={NewTagsRequestTestIds.SuccessModal}
            >
                <ModalContent
                    testId={NewTagsRequestTestIds.SuccessModalContent}
                >
                    <div className="success-modal-question">
                        Request Submission Successful!
                    </div>
                    <div className="success-modal-text">
                        Request {tagRequest.requestName} was successfully
                        submitted!
                    </div>
                </ModalContent>
                <ModalActions
                    testId={NewTagsRequestTestIds.SuccessModalActions}
                >
                    <Button
                        variant="outline"
                        color="primary"
                        onClick={onSuccessModalClosed}
                        text="Return to My Requests"
                        testId={NewTagsRequestTestIds.SuccessModalCloseButton}
                    />
                </ModalActions>
            </Modal>
        </div>
    );
}
