/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
import { ConfirmToggle } from "@components/display/confirm-toggle/confirm-toggle";
import { Button, DataGridRow, Select } from "@cpchem/covalence-ui";
import {
    faMemoPad,
    faTrash,
    faTrashUndo
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagReviewTestIds } from "@pages/tag-management-page/test-ids";
import { Change, Log, Tag } from "@services/hermes/model";
import { useTagManagement } from "@stores/tag-management-context";
import React, { useState } from "react";
import "./styles.scss";

interface ApprovalTagReviewRowProps {
    row: DataGridRow;
    updateTagRow: (
        tagName: string | undefined,
        pullFrequency: string | undefined,
        comments: Log | null | undefined,
        frequencyMode?: string | undefined
    ) => void;
    removeTagRow: (
        tagName: string | undefined,
        tagStatus: string | null,
        reasonForChange: string
    ) => void;
    changeTagRowStatus: (
        tagName: string | undefined,
        tagStatus: string | null
    ) => void;
}

export const ApprovalTagReviewRow = ({
    row,
    updateTagRow,
    removeTagRow,
    changeTagRowStatus
}: ApprovalTagReviewRowProps) => {
    const { state } = useTagManagement();
    const { tagRequest } = state;
    const tag = row.values as Tag;
    const key = Number(row.id) + 1;
    const [isEditing, setIsEditing] = useState(false);
    const [editedTag, setEditedTag] = useState(tag);
    const [reasonForChange, setReasonForChange] = useState("");
    const [commentRequiredError, setCommentRequiredError] = useState(false);
    const requestApproved =
        tagRequest.requestStatus?.toLowerCase() === "approved";
    let tagRowStyle = "";
    let memoPadStyle = "";
    let editRowStyle = "";
    const removedTag = tag.tagStatus?.toLowerCase() === "removed";
    const confirmedTag =
        tag.tagStatus?.toLowerCase() === "confirmed" ||
        tag.tagStatus?.toLowerCase() === "complete";
    if (removedTag) {
        tagRowStyle = "tag-review-row-removed";
        editRowStyle = "edit-row-removed";
    } else if (isEditing) {
        tagRowStyle = "tag-review-row-edited";
        editRowStyle = "edit-row-edited";
    } else {
        tagRowStyle = "tag-review-row-pending";
        editRowStyle = "edit-row-edited";
    }

    memoPadStyle =
        tag.commentCount > 0 ? "memo-pad-enabled" : "memo-pad-disabled";

    const handleEditChange = (field: string, value: string) => {
        if (
            field === "reasonForChange" &&
            (editedTag.pullFrequency !== tag.pullFrequency ||
                editedTag.frequencyMode !== tag.frequencyMode)
        ) {
            setReasonForChange(value);
            if (value) {
                setCommentRequiredError(false);
            } else {
                setCommentRequiredError(true);
            }
        } else if (
            editedTag.pullFrequency === tag.pullFrequency &&
            editedTag.frequencyMode === tag.frequencyMode &&
            field === "reasonForChange"
        ) {
            setReasonForChange(value);
            if (value) {
                setReasonForChange(value);
                setCommentRequiredError(false);
            }
        }
        setEditedTag((prev) => ({ ...prev, [field]: value }));
    };

    const isIconDisabled: React.CSSProperties = confirmedTag
        ? {
              pointerEvents: "none",
              opacity: "0.5",
              cursor: "not-allowed"
          }
        : { pointerEvents: "auto" };

    const textAreaStyle: React.CSSProperties = commentRequiredError
        ? { borderColor: "var(--danger)" }
        : { borderColor: "#767676" };

    const saveChanges = () => {
        if (
            (editedTag.pullFrequency !== tag.pullFrequency ||
                editedTag.frequencyMode !== tag.frequencyMode) &&
            reasonForChange.length === 0
        ) {
            setCommentRequiredError(true);
        } else {
            const tagChanges: Change[] = [];
            if (editedTag.pullFrequency !== tag.pullFrequency) {
                const pullFreqChange: Change = {
                    name: "pullFrequency",
                    from: tag.pullFrequency,
                    to: editedTag.pullFrequency
                };
                tagChanges.push(pullFreqChange);
            }
            if (editedTag.frequencyMode !== tag.frequencyMode) {
                const freqModeChange: Change = {
                    name: "frequencyMode",
                    from: tag.frequencyMode,
                    to: editedTag.frequencyMode
                };
                tagChanges.push(freqModeChange);
            }

            const tagLog: Log = {
                reasonForChange: reasonForChange,
                tagUpdates: tagChanges
            };

            updateTagRow(
                editedTag.tagName,
                editedTag.pullFrequency,
                tagLog,
                editedTag.frequencyMode
            );
            setIsEditing(false);
            setCommentRequiredError(false);
        }
    };

    const removeTag = () => {
        if (reasonForChange === "") {
            setCommentRequiredError(true);
        } else {
            removeTagRow(tag.tagName, "removed", reasonForChange);
            setIsEditing(false);
            setCommentRequiredError(false);
        }
    };

    const undoTagRemoval = () => {
        if (reasonForChange === "") {
            setCommentRequiredError(true);
        } else {
            removeTagRow(tag.tagName, "pending", reasonForChange);
            setIsEditing(false);
            setCommentRequiredError(false);
        }
    };

    const cancelEdit = () => {
        setCommentRequiredError(false);
        setIsEditing(false);
        setEditedTag(tag);
    };

    function openTagActivityPopup() {
        return <>Some Popup</>;
    }

    return (
        <>
            <tr key={key} className={tagRowStyle}>
                <td className="items-cell">{tag.site}</td>
                <td className="items-cell">{tag.source}</td>
                {!isEditing && !confirmedTag && (
                    <td className="items-cell">
                        <span
                            onClick={() => setIsEditing(!isEditing)}
                            onKeyDown={() => setIsEditing(!isEditing)}
                            role="button"
                            tabIndex={0}
                            className="tag-name-link"
                            data-testid={`${TagReviewTestIds.EditTagButton}-${tag.tagName}`}
                        >
                            {tag.tagName}
                        </span>
                    </td>
                )}
                {!isEditing && confirmedTag && (
                    <td className="items-cell">{tag.tagName}</td>
                )}
                {isEditing && (
                    <td className="items-cell">
                        <span
                            onClick={() => setIsEditing(!isEditing)}
                            onKeyDown={() => setIsEditing(!isEditing)}
                            role="button"
                            tabIndex={0}
                            data-testid={`${TagReviewTestIds.EditTagButton}-${tag.tagName}`}
                        >
                            {tag.tagName}
                        </span>
                    </td>
                )}
                <td className="items-cell">{tag.engUnits}</td>
                <td className="items-cell">{tag.sourceFrequency}</td>
                <td className="items-cell">{tag.aggregationType}</td>
                {isEditing && removedTag && (
                    <td className="items-cell">{tag.pullFrequency}</td>
                )}
                {isEditing && !removedTag && (
                    <td className="items-cell">
                        <Select
                            value={editedTag.pullFrequency || tag.pullFrequency}
                            options={state.pullFrequencySelectOptions}
                            className="select-dropdown"
                            onChange={(pullFrequency) =>
                                handleEditChange("pullFrequency", pullFrequency)
                            }
                            testId={`${TagReviewTestIds.PullFrequencySelect}-${tag.tagName}`}
                            isLabelHidden
                            label={""}
                        />
                    </td>
                )}
                {isEditing && removedTag && (
                    <td className="items-cell">{tag.frequencyMode}</td>
                )}
                {isEditing && !removedTag && (
                    <td className="items-cell">
                        <Select
                            value={editedTag.frequencyMode || tag.frequencyMode}
                            options={state.frequencyModeSelectOptions}
                            className="select-dropdown"
                            onChange={(frequencyMode) =>
                                handleEditChange("frequencyMode", frequencyMode)
                            }
                            testId={`${TagReviewTestIds.FrequencyModeSelect}-${tag.tagName}`}
                            isLabelHidden
                            label={""}
                        />
                    </td>
                )}
                {!isEditing && (
                    <td className="items-cell">{tag.pullFrequency}</td>
                )}
                {!isEditing && (
                    <td className="items-cell">{tag.frequencyMode}</td>
                )}
                <td>
                    {
                        <FontAwesomeIcon
                            icon={faMemoPad}
                            className={memoPadStyle}
                            size="sm"
                            onClick={openTagActivityPopup}
                            data-testid={`${TagReviewTestIds.MemoPadTagIcon}-${tag.tagName}`}
                        />
                    }{" "}
                    {tag.commentCount > 0 ? tag.commentCount : ""}
                </td>
                <td style={{ textAlign: "center" }}>
                    {!removedTag && !isEditing && (
                        <ConfirmToggle
                            isChecked={
                                tag.tagStatus?.toLowerCase() === "confirmed"
                            }
                            isDisabled={requestApproved}
                            onChange={() =>
                                changeTagRowStatus(tag.tagName, tag.tagStatus)
                            }
                            testId={`${TagReviewTestIds.ConfirmToggle}-${tag.tagName}`}
                        />
                    )}
                </td>
            </tr>
            {isEditing && (
                <tr className={editRowStyle}>
                    <td colSpan={10}>
                        <div className="edit-container">
                            <div className="edit-tag-text-area-container">
                                <div className="edit-tag-text-area-panel">
                                    <p>Reason For Change</p>
                                    {commentRequiredError && (
                                        <p
                                            className="comment-required"
                                            data-testid={`${TagReviewTestIds.ReasonRequired}-${tag.tagName}`}
                                        >
                                            &nbsp; *Required
                                        </p>
                                    )}
                                </div>
                                <textarea
                                    placeholder="Enter Reason For Change Here..."
                                    style={textAreaStyle}
                                    title="Reason for change"
                                    onChange={(e) =>
                                        handleEditChange(
                                            "reasonForChange",
                                            e.target.value
                                        )
                                    }
                                    data-testid={`${TagReviewTestIds.ReasonForChange}-${tag.tagName}`}
                                    className="edit-tag-text-area"
                                />
                            </div>
                            <div className="edit-tag-panel-buttons">
                                <div className="remove-tag-panel-button">
                                    {!removedTag && isEditing && (
                                        <Button
                                            variant="solid"
                                            size="medium"
                                            icon={
                                                <FontAwesomeIcon
                                                    style={isIconDisabled}
                                                    icon={faTrash}
                                                    className="remove-tag-button-icon"
                                                    size="lg"
                                                    data-testid={`${TagReviewTestIds.RemoveTagIcon}-${tag.tagName}`}
                                                />
                                            }
                                            isIconAfterText
                                            text="Remove Tag"
                                            color="danger"
                                            onClick={removeTag}
                                            testId={`${TagReviewTestIds.Remove}-${tag.tagName}`}
                                        />
                                    )}
                                </div>
                                <div className="save-tag-panel-buttons">
                                    <Button
                                        variant="outline"
                                        size="medium"
                                        text="Cancel"
                                        className="cancel-tag-edit"
                                        color="secondary"
                                        onClick={cancelEdit}
                                        testId={`${TagReviewTestIds.CancelTagEdit}-${tag.tagName}`}
                                    />
                                    {removedTag && isEditing && (
                                        <Button
                                            variant="solid"
                                            size="medium"
                                            icon={
                                                <FontAwesomeIcon
                                                    icon={faTrashUndo}
                                                    className="undo-remove-tag"
                                                    size="sm"
                                                    data-testid={`${TagReviewTestIds.UndoRemoveTagIcon}-${tag.tagName}`}
                                                />
                                            }
                                            isIconAfterText
                                            text="Add Tag Back"
                                            color="primary"
                                            onClick={undoTagRemoval}
                                            testId={`${TagReviewTestIds.UndoRemove}-${tag.tagName}`}
                                        />
                                    )}
                                    {!removedTag && isEditing && (
                                        <Button
                                            variant="solid"
                                            size="medium"
                                            text="Save"
                                            className="save-tag-edit"
                                            color="primary"
                                            onClick={saveChanges}
                                            testId={`${TagReviewTestIds.SaveTagEdit}-${tag.tagName}`}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};
