/* eslint-disable react-hooks/exhaustive-deps */
import { DataGridColumnSortState } from "@components/display/base-header-display/constants";
import { useTagManagementContext } from "@pages/tag-management-page/context";
import { HermesServiceKey } from "@services";
import {
    GetMyApprovalsAPIRequest,
    HermesService,
    InitialFilterState,
    InitialMyApprovalsDataState,
    SearchMyApprovalsAPIRequest
} from "@services/hermes/interface";
import { MyApprovalsData } from "@services/hermes/model";
import {
    FilterType,
    SITE_FILTER,
    SOURCE_SYSTEM_FILTER
} from "@stores/filters/filter-names";
import { useTagManagement } from "@stores/tag-management-context";
import { buildFilterListItemList } from "@utilities/filters/build-filter-list-item-list";
import { getSelectedFiltersFromList } from "@utilities/filters/set-selected-filters-from-list-on-load";
import React, { useEffect } from "react";
import { useService } from "../../../../../service-provider";
import { ApprovalsTableView } from "../approvals-table-view";

export function MyApprovalsTableView(): JSX.Element {
    const { state, dispatch } = useTagManagement();
    const hermesService = useService<HermesService>(HermesServiceKey);
    const myApprovalsRequest: GetMyApprovalsAPIRequest = {
        start: state.currentPage,
        end: state.currentPage,
        pageSize: state.rowsToDisplay,
        siteFilters: getSelectedFiltersFromList(state.siteFilters),
        sourceSystemFilters: getSelectedFiltersFromList(
            state.sourceSystemFilters
        ),
        myApprovalsStatus: state.myApprovalsStatus,
        orderBy: state.sortValue.column.toLowerCase(),
        desc: state.sortValue.sort === DataGridColumnSortState.DESC
    };
    const searchMyApprovalsRequest: SearchMyApprovalsAPIRequest = {
        fieldName: state.searchFieldName || "requestName",
        value: state.searchValue,
        start: state.currentPage,
        end: state.currentPage,
        pageSize: state.rowsToDisplay,
        siteFilters: getSelectedFiltersFromList(state.siteFilters),
        sourceSystemFilters: getSelectedFiltersFromList(
            state.sourceSystemFilters
        ),
        myApprovalsStatus: state.myApprovalsStatus,
        orderBy: state.sortValue.column.toLowerCase() || "site",
        desc: state.sortValue.sort === DataGridColumnSortState.DESC
    };
    const { isLoading, onSetLoading } = useTagManagementContext();
    async function getMyApprovals() {
        if (state.sortValue.sort === DataGridColumnSortState.WAIT) return;
        try {
            let response;
            onSetLoading(true);

            if (!state.searchValue) {
                response =
                    await hermesService.GetMyApprovals(myApprovalsRequest);
            } else {
                response = await hermesService.SearchMyApprovals(
                    searchMyApprovalsRequest
                );
            }

            if (response.data) {
                const data = response.data as MyApprovalsData;
                setMyApprovalsState(data);
            }
        } catch (error) {
            setMyApprovalsState(InitialMyApprovalsDataState);
        } finally {
            onSetLoading(false);
        }
    }
    function setMyApprovalsState(data: MyApprovalsData) {
        dispatch({
            type: "SET_MY_APPROVALS_DATA",
            payload: {
                totalPendingApprovalCount: data.totalPendingCount,
                totalCompleteApprovalCount: data.totalCompleteCount,
                myApprovals: data.items
            }
        });
    }

    async function setFiltersState(filterName: string, data: string[]) {
        const filterActions = {
            [SITE_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SITE_FILTER",
                    payload: {
                        siteFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.siteFilters ?? []
                        )
                    }
                });
            },
            [SOURCE_SYSTEM_FILTER.TITLE]: () => {
                dispatch({
                    type: "SET_SOURCE_SYSTEM_FILTER",
                    payload: {
                        sourceSystemFilters: buildFilterListItemList(
                            data,
                            filterName,
                            state.sourceSystemFilters ?? []
                        )
                    }
                });
            }
        };

        const action = filterActions[filterName];
        if (action) action();
        else console.error("Unknown filterName", filterName);
    }

    async function getFilterItems(filterType: FilterType) {
        try {
            const filterResponse =
                await hermesService.GetFilterItemsForFilterID(filterType.ID);

            if (filterResponse.data) {
                const data = filterResponse.data as string[];
                setFiltersState(filterType.TITLE, data);
            }
        } catch (error) {
            setFiltersState(filterType.TITLE, InitialFilterState.filterItems);
        }
    }

    async function getAllInitialFilterItems() {
        getFilterItems(SITE_FILTER);
        getFilterItems(SOURCE_SYSTEM_FILTER);
    }

    useEffect(() => {
        getAllInitialFilterItems();
    }, []);

    useEffect(() => {
        getMyApprovals();
    }, [
        state.currentPage,
        state.rowsToDisplay,
        state.sortValue,
        state.searchFieldName,
        state.searchValue,
        state.siteFilters,
        state.sourceSystemFilters,
        state.myApprovalsStatus,
        dispatch
    ]);

    return (
        <div className="my-approvals-table-view">
            <div className="my-approvals-table">
                <div className="my-approvals">
                    <div className="header" data-testid="">
                        <p className="title" data-testid="">
                            My Approvals
                        </p>
                    </div>
                    {!isLoading && <ApprovalsTableView />}
                </div>
            </div>
        </div>
    );
}
