import {
    AGGREGATION_TYPE_FILTER,
    SITE_FILTER,
    SOURCE_FREQUENCY_FILTER,
    SOURCE_SYSTEM_FILTER,
    STATUS_FILTER
} from "@stores/filters/filter-names";

export const TagManagementTestIds = {
    AppName: "app-name",
    AvatarMenu: "avatar-menu",
    AvatarProfile: "avatar-profile",
    TagManagementPage: "tag-management-page",
    TagManagementView: "tag-management-view",
    TagManagementTableViewHeader: "tag-management-table-view-header",
    TagManagementTableViewTitle: "tag-management-table-view-title",
    TagManagementTableViewWelcomeMessage:
        "tag-management-table-view-welcome-message",
    NoConfiguredTagsAlert: "no-configured-tags-alert",
    SearchBox: "search-box",
    FilterButton: "filter-button",
    FilterContainer: "filter-container",
    HelpPage: "help-page",
    EnvIndicator: "environment-indicator",
    HelpPageButton: "help-page-button",
    HelpPageModal: "help-page-modal",
    AppVersion: "app-version",
    AppBuildDate: "app-build-date",
    DigitalWorkplaceLink: "digital-workplace-link"
};

export const HermesAppDrawerTestIds = {
    ToggleButton: "hermes-app-drawer-toggle-button",
    TagManagementLink: "tag-management",
    MyApprovalsLink: "my-approvals",
    MyRequestsLink: "my-requests",
    PowerBILink: 'powerbi"'
};

export const TagManagementTableViewTestIds = {};

export const IngestedTagsTableTestIds = {
    IngestedTagsGrid: "ingested-tags-grid",
    IngestedTagsCard: "ingested-tags-card",
    IngestedTagsContent: "ingested-tags-content",
    GridBody: "grid-body",
    EmptySearchResults: "empty-search-results"
};

export const FilterDrawerTestIds = {
    FilterList: "filter-list",
    FilterListAccordion: "accordion",
    FilterListCheckBox: "checkbox",
    FilterListSearchBox: "search-box",
    FilterListTooltip: "tooltip",
    ClearFiltersButton: "clear-filters-button",
    ApplyFiltersButton: "apply-filters-button",
    SiteFilterBadge: SITE_FILTER.ID,
    SourceSystemFilterBadge: SOURCE_SYSTEM_FILTER.ID,
    SourceFrequencyFilterBadge: SOURCE_FREQUENCY_FILTER.ID,
    AggregationTypeFilterBadge: AGGREGATION_TYPE_FILTER.ID,
    StatusFilterBadge: STATUS_FILTER.ID
};

export const MyRequestsTestIds = {
    MyRequestsPage: "my-requests-page",
    MyRequestsView: "my-requests-view",
    MyRequestsTableViewHeader: "my-requests-table-view-header",
    MyRequestsTableViewTitle: "my-requests-table-view-title",
    NoRequestsAlert: "no-requests-alert",
    SearchBox: "search-box",
    FilterButton: "filter-button",
    FilterContainer: "filter-container",
    MyRequestsGrid: "my-requests-grid",
    MyRequestsCard: "my-requests-card",
    MyRequestsContent: "my-requests-content",
    GridBody: "grid-body",
    EmptySearchResults: "empty-search-results",
    NewRequestButton: "new-request-button"
};

export const MyApprovalsTestIds = {
    MyApprovalsPage: "my-approvals-page",
    MyApprovalsView: "my-approvals-view",
    MyApprovalsTableViewHeader: "my-approvals-table-view-header",
    MyApprovalsTableViewTitle: "my-approvals-table-view-title",
    NoApprovalsAlert: "no-approvals-alert",
    SearchBox: "search-box",
    FilterButton: "filter-button",
    FilterContainer: "filter-container",
    MyApprovalsGrid: "my-approvals-grid",
    MyApprovalsCard: "my-approvals-card",
    MyApprovalsContent: "my-approvals-content",
    GridBody: "grid-body",
    EmptySearchResults: "empty-search-results",
    NewApprovalsButton: "new-approval-button"
};

export const RequestDetailsTestIds = {
    RequestDetailsView: "request-details-view",
    RequestPreview: "request-preview"
};

export const ApprovalDetailsTestIds = {
    ApprovalDetailsView: "approval-details-view",
    ApprovalPreview: "approval-preview"
};

export const RequestTitleViewTestIds = {
    RequestHeader: "request-header",
    RequestTitle: "request-title",
    RequestWelcomeMessage: "request-welcome-message",
    HermesAccordion: "hermes-accordion"
};

export const NewTagsRequestTestIds = {
    CreateNewTagsRequestView: "create-new-tags-request-view",
    CreateNewRequestViewHeader: "create-new-request-view-header",
    CreateNewRequestViewTitle: "create-new-request-view-title",
    NewRequestSelectOptionsView: "new-request-select-options-view",
    SiteSelectOptionsView: "site-select-options-view",
    PullFrequencySelectOptionsView: "pull-frequency-select-options-view",
    SourceSystemSelectOptionsView: "source-system-select-options-view",
    TagNameSelectOptionsView: "tag-name-select-options-view",
    AddTagToTableButton: "add-tag-to-table-button",
    ClearTableButton: "clear-table-button",
    NewRequestDetailsViewHeader: "new-request-details-view-header",
    RequestNameText: "request-name-text",
    BusinessJustificationText: "business-justification-text",
    AddUploadTagsGrid: "add-upload-tags-grid",
    GridBody: "grid-body",
    SubmitButton: "submit-button",
    TrashIcon: "trash-icon",
    AlertMessage: "alert-message",
    PullFrequencySelectOptionsViewControl:
        "pull-frequency-select-options-view-select-control",
    AddUploadTagsGridEmptyMessage: "add-upload-tags-grid-empty-message",
    TagNameSelectOptionsViewControl:
        "tag-name-select-options-view-select-control",
    SourceSystemSelectOptionsViewControl:
        "source-system-select-options-view-select-control",
    SiteSelectOptionsViewControl: "site-select-options-view-select-control",
    SourceSystemsSelectOptionsViewSelect:
        "source-system-select-options-view-select",
    SuccessModal: "new-request-success-modal",
    SuccessModalContent: "new-request-success-modal-content",
    SuccessModalActions: "new-request-success-modal-actions",
    SuccessModalCloseButton: "new-request-success-modal-close-button",
    ClearTableModal: "clear-table-modal",
    ClearTableModalContent: "clear-table-content",
    ClearTableModalActions: "clear-table-modal-actions",
    ClearTableModalCancelButton: "clear-table-modal-cancel-button",
    ClearTableModalConfirmButton: "clear-table-modal-confirm-button",
    EditTagIcon: "edit-tag-icon",
    DeleteTagIcon: "delete-tag-icon",
    CancelTagEdit: "cancel-tag-edit",
    SaveTagEdit: "save-tag-edit",
    TagNameSearch: "tag-name-search",
    TagNameListBox: "tag-name-list-box",
    TagNameSearchBoxInput: "tag-name-search-searchbox-input"
};

export const RequestPreviewTestIds = {
    SuccessModal: "request-preview-success-modal",
    SuccessModalContent: "request-preview-success-modal-content",
    SuccessModalActions: "request-preview-success-modal-actions",
    SuccessModalCloseButton: "request-preview-success-modal-close-button",
    RequestTitleViewCard: "request-title-view-card",
    RequestTagsReviewDisplayCard: "request-tags-review-display-card"
};

export const ApprovalPreviewTestIds = {
    SuccessModal: "approval-preview-success-modal",
    SuccessModalContent: "approval-preview-success-modal-content",
    SuccessModalActions: "approval-preview-success-modal-actions",
    SuccessModalCloseButton: "approval-preview-success-modal-close-button",
    ApprovalTagsReviewDisplayCard: "approval-tags-review-display-card",
    RequestTagsReviewDisplayCard: "request-tags-review-display-card",
    RequestTitleViewCard: "request-title-view-card",
    ApproveRequestPanelCard: "approve-request-panel-card",
    ApproveRequestButton: "approve-request-button"
};

export const HermesAccordionTestIds = {
    AccordionTitle: "hermes-accordion-title",
    Badge: "hermes-accordion-badge",
    ToggleButton: "hermes-accordion-toggle-button",
    AccordionFooter: "hermes-accordion-footer",
    FooterText: "hermes-accordion-footer-text"
};

export const ApprovalTagsReviewDisplayTestIds = {
    StatusToggle: "status-toggle",
    StatusToggleLabel: "status-toggle-label",
    StatusToggleText: "status-toggle-text",
    DataGrid: "tag-review-data-grid",
    GridBody: "grid-body",
    ConfirmAllToggle: "confirm-all-toggle"
};

export const RequestTagsReviewDisplayTestIds = {
    StatusToggle: "status-toggle",
    DataGrid: "data-grid",
    GridBody: "grid-body",
    StatusToggleText: "status-toggle-text",
    StatusToggleLabel: "status-toggle-label"
};

export const TagReviewTestIds = {
    PullFrequencySelect: "pull-frequency-select",
    FrequencyModeSelect: "frequency-mode-select",
    EditTagButton: "edit-tag-button",
    EditTagIcon: "edit-tag-icon",
    RemoveTagIcon: "remove-tag-icon",
    MemoPadTagIcon: "memo-pad-icon",
    UndoRemoveTagIcon: "undo-remove-tag-icon",
    ConfirmToggle: "confirm-toggle",
    CancelTagEdit: "cancel-tag-edit",
    SaveTagEdit: "save-tag-edit",
    UndoRemove: "undo-remove",
    Remove: "remove",
    ReasonRequired: "reason-required",
    ReasonForChange: "reason-for-change",
    ToastProvider: "toast-provider"
};

export const TestIds = {
    TagManagement: TagManagementTestIds,
    IngestedTags: IngestedTagsTableTestIds,
    AppDrawer: HermesAppDrawerTestIds,
    FilterDrawer: FilterDrawerTestIds,
    MyRequests: MyRequestsTestIds,
    MyApprovals: MyApprovalsTestIds,
    NewRequest: NewTagsRequestTestIds,
    RequestTitle: RequestTitleViewTestIds,
    RequestPreview: RequestPreviewTestIds,
    ApprovalPreview: ApprovalPreviewTestIds,
    HermesAccordion: HermesAccordionTestIds,
    ApprovalTagsDisplay: ApprovalTagsReviewDisplayTestIds,
    RequestTagsDisplay: RequestTagsReviewDisplayTestIds,
    TagReview: TagReviewTestIds
};
